import _ from 'lodash';

const expandMemberData = (member = {}) => {
  // console.log('member --><:>> ', member);
  const copyMember = _.cloneDeep(member);
  let data;

  try {
    if (copyMember.data) {
      data = JSON.parse(copyMember.data);
      delete data.viewCount;
    }
  } catch (error) {
    // console.error(error);
  }
  const expandedMemberData = { ...data, ..._.cloneDeep(copyMember) };
  delete expandedMemberData.data;

  return expandedMemberData;
};

export default expandMemberData;
