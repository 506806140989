/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';
import _, { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  filterArchived,
  filterContractorData,
  filterEmergencyContractorData,
  filterNewContractorData,
  filterPendingContractorData,
  filterContractorDataById,
  filterAdminContractors,
  filterOnlyMembers,
  filterOnlyArchivedMembers,
  transformContractorData,
} from '../../functions/utils/occupations';
import { getAuthenticated } from './user';
import fetchPublic from '../lib/fetchPublic';
import expandMemberData from '../lib/expandMemberData';

/* https://redux-toolkit.js.org/ */

const updateObjectInsideArray = (identifier, data) => {
  const dataCopy = [...data];
  const index = _.findIndex(dataCopy, { _id: identifier });
  return (newObj) => {
    // eslint-disable-next-line no-param-reassign
    dataCopy.splice(index, 1, { ...data[index], ...newObj });
    return dataCopy;
  };
};

/* Actions */
export const fetchMembers = createAction('MEMBERS/FETCH');
export const setIsfetchingMembers = createAction('MEMBERS/FETCHING_MEMBERS');
export const setMembersSuccess = createAction('MEMBERS/SET_MEMBERS_SUCCESS');
export const setMembersFailure = createAction('MEMBERS/SET_MEMBERS_FAILURE');

export const updateMembers = createAction('MEMBERS/UPDATE_MEMBERS');
export const setUpdateMembersPending = createAction(
  'MEMBERS/UPDATE_MEMBERS_PENDING',
);
export const setUpdateMembersSuccess = createAction(
  'MEMBERS/SET_UPDATE_MEMBERS_SUCCESS',
);
export const setUpdateMembersFailure = createAction(
  'MEMBERS/SET_UPDATE_MEMBERS_FAILURE',
);

export const Actions = { fetchMembers, setMembersSuccess, setMembersFailure };

/* Selectors */
export const getMembers = (state) => filterArchived(get(state, 'members.members', []));
export const Selectors = { getMembers };
export const getIsFetchingMembers = (state) => get(state, 'members.isFetchingMembers', false);
export const getIsUpdatingMembersData = (state) => get(state, 'members.isUpdating', false);
export const getMembersByType = (state) => (type) => filterContractorData(get(state, 'members.members', []), type);
export const getEmergencyMembers = (state) => filterEmergencyContractorData(get(state, 'members.members', []));
export const getNewMembers = (state) => filterNewContractorData(get(state, 'members.members', []));
export const getMembersOnly = (state) => filterArchived(filterOnlyMembers(get(state, 'members.members', [])));
export const getPendingMembers = (state) => filterPendingContractorData(get(state, 'members.members', []));
export const getMembersByID = (id) => (state) => filterContractorDataById(get(state, 'members.members', []), id);
export const getAdminMembers = (state) => filterAdminContractors(get(state, 'members.members', []));
export const getAllSpecialities = (state) => transformContractorData(get(state, 'members.members', []));
export const getArchivedMembers = (state) => filterOnlyArchivedMembers(get(state, 'members.members', []));
/* Initial State */
export const initialState = { members: [], errors: [] };

/* Reducer */
const setIsFetchingMembersReducer = (state) => ({
  ...state,
  isFetchingMembers: true,
});
const setMembersSuccessReducer = (state, action) => ({
  ...state,
  members: action.payload.map((member) => expandMemberData(member)),
  errors: [],
  isFetchingMembers: false,
});
const setMembersFailureReducer = (state, action) => ({
  ...state,
  members: [],
  errors: action.payload,
  isFetchingMembers: false,
});

const setUpdateMembersPendingReducer = (state) => ({
  ...state,
  isUpdating: true,
});
const setUpdateMembersSuccessReducer = (state, action) => ({
  // eslint-disable-next-line no-underscore-dangle
  ...state,
  isUpdating: false,
  members: updateObjectInsideArray(
    action.payload.data.member._id,
    state.members,
  )(action.payload.data.member),
});
const setUpdateMembersFailureReducer = (state, action) => ({
  ...state,
  isUpdating: false,
  error: action.payload,
});

const memberReducer = createReducer(initialState, {
  [setIsfetchingMembers]: setIsFetchingMembersReducer,
  [setMembersSuccess]: setMembersSuccessReducer,
  [setMembersFailure]: setMembersFailureReducer,
  [setUpdateMembersPending]: setUpdateMembersPendingReducer,
  [setUpdateMembersSuccess]: setUpdateMembersSuccessReducer,
  [setUpdateMembersFailure]: setUpdateMembersFailureReducer,
});

export default memberReducer;

/* Sagas */

function* fetchUpdateMember({ payload }) {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib');
  try {
    const { action } = payload;
    yield put(setUpdateMembersPending());
    const member = yield call(
      action === 'updateViewCount' ? fetchPublic : fetchWAuth,
      action === 'updateViewCount'
        ? '/.netlify/functions/update-public'
        : '/.netlify/functions/update-member-info',
      {},
      payload,
    ) || {};
    if (!member || member.errors) {
      alert('Error updating member info');
      yield put(setUpdateMembersFailure(member.errors));
    } else {
      yield put(setUpdateMembersSuccess(member));
    }
  } catch (error) {
    alert('Error updating member info');
    yield put(setUpdateMembersFailure([error]));
  }
}

export function* watchUpdateMembers() {
  yield takeEvery(updateMembers().type, fetchUpdateMember);
}

export function* fetchRemoteMembers() {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib.js');
  const isAuthenticated = yield select(getAuthenticated);
  try {
    yield put(setIsfetchingMembers());
    const { members = {} } = yield call(
      isAuthenticated ? fetchWAuth : fetchPublic,
      '/.netlify/functions/get-all-members',
    );
    if (!members || members.errors) {
      alert('Error fetching remote members');
      yield put(setMembersFailure(members.errors));
    } else {
      yield put(setMembersSuccess(get(members.data, 'allMembers.data', [])));
    }
  } catch (error) {
    alert('Error fetching remote members');
    yield put(setMembersFailure([error]));
  }
}

export function* watchFetchMembers() {
  yield takeEvery(fetchMembers().type, fetchRemoteMembers);
}

export const Sagas = [watchFetchMembers, watchUpdateMembers];
