/**
 *
 * @param {*} url URL to Fetch
 * @param {*} config Fetch Config Object to override default
  */

const fetchPublic = (
  url,
  config = {},
  data,
) => {
  const fetchDataAsync = async () => {
    const baseConfig = {
      method: 'POST',
      body: JSON.stringify(data),
    };

    return fetch(url, { ...baseConfig, ...config })
      .then((res) => res.json())
      .catch((errMessage) => {
        // eslint-disable-next-line no-console
        console.error(errMessage);
      });
  };

  return fetchDataAsync();
};

export default fetchPublic;
