/* eslint-disable no-alert */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { put, takeLeading } from 'redux-saga/effects';
import { get } from 'lodash';
import { fetchMembers } from './members';
import { fetchMyInfo } from './member';
import { fetchEvents } from './event';
import { fetchEventsItems } from './eventsItems';
import { fetchPurchases } from './purchase';
import { product } from './stripeProducts';

export const appInit = createAction('APP/LOAD');
export const appLoadFailure = createAction('APP/LOAD_FAILURE');
export const toggleMemberFullScreen = createAction('APP/TOGGLE_MEMBER_FULL_SCREEN');

export const getMemberFullScreen = (state) => get(state, 'app.fullScreen', false);

/* Initial State */
export const initialState = { fullScreen: false };

/* Reducer */
const toggleMembersFullScreenReducer = (state) => ({
  ...state,
  fullScreen: !getMemberFullScreen({ app: { ...state } }),
});

const appReducer = createReducer(initialState, {
  [toggleMemberFullScreen]: toggleMembersFullScreenReducer,
});

export default appReducer;

function* appInitialization() {
  try {
    yield put(fetchMyInfo());
    yield put(fetchMembers());
    yield put(fetchEvents());
    yield put(fetchEventsItems());
    yield put(fetchPurchases());
    yield put(product());
  } catch (error) {
    alert('Error loading application');
    yield put(appLoadFailure([error]));
  }
}

export function* watchAppLoad() {
  yield takeLeading(appInit().type, appInitialization);
}

export const Sagas = [
  watchAppLoad,
];
