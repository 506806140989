/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  put, takeEvery, call,
} from 'redux-saga/effects';
import _, { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { fetchMembers } from './members';
import store from './configureStore';
import expandMemberData from '../lib/expandMemberData.js';
/* https://redux-toolkit.js.org/ */

/* Actions */
export const fetchMyInfo = createAction('MEMBER/FETCH_MY_INFO');
export const setMyInfoPending = createAction('MEMBER/FETCH_MY_INFO_PENDING');
export const setMyInfoSuccess = createAction('MEMBER/SET_MY_INFO_SUCCESS');
export const setMyInfoFailure = createAction('MEMBER/SET_MY_INFO_FAILURE');

export const updateMember = createAction('MEMBER/UPDATE_MEMBER');
export const setUpdateMemberSuccess = createAction('MEMBER/SET_UPDATE_MEMBER_SUCCESS');
export const setUpdateMemberFailure = createAction('MEMBER/SET_UPDATE_MEMBER_FAILURE');

export const setUpdateMemberSurvey = createAction('MEMBER/SET_UPDATE_MEMBER_SURVEY');

export const Actions = { updateMember, setUpdateMemberSuccess, setUpdateMemberFailure };

/* Selectors */

export const getMyInfo = (state) => get(state, 'member.member');
export const getMemberPurchases = (state) => get(state, 'member.member.memberPurchases.data', []);
export const isGuest = (state) => get(state, 'member.member.role.isGuest', true);
export const isPending = (state) => get(state, 'member.member.role.isPending', false);
export const isReviewed = (state) => get(state, 'member.member.adminReviewed', false);
export const isMember = (state) => get(state, 'member.member.role.isMember', false);
export const isAdmin = (state) => get(state, 'member.member.role.isAdmin', false);
export const isSuperAdmin = (state) => get(state, 'member.member.role.isSuperAdmin', false);
export const getIsFetchingMyInfo = (state) => get(state, 'member.isFetchingMember', false);
export const getIsActive = (state) => get(state, 'member.member.isMemberShipValid');

/* Initial State */
export const initialState = { member: {}, errors: [] };

/* Reducer */
const setMembersUpdateSuccessReducer = (state, action) => ({ ...state, member: expandMemberData(action.payload.data.member), errors: [] });
const setMembersUpdateFailureReducer = (state, action) => ({ ...state, member: {}, errors: action.payload });
const setMyInfoPendingReducer = (state) => ({
  ...state, isFetchingMember: true, member: {}, errors: [],
});
const setMyInfoSuccessReducer = (state, action) => {
  const expandedMemberData = expandMemberData(action.payload);
  return ({
    ...state, isFetchingMember: false, member: expandedMemberData, errors: [],
  });
};
const setMyInfoFailureReducer = (state, action) => ({
  ...state, isFetchingMember: false, member: {}, errors: action.payload,
});

const memberReducer = createReducer(initialState, {
  [setUpdateMemberSuccess]: setMembersUpdateSuccessReducer,
  [setUpdateMemberFailure]: setMembersUpdateFailureReducer,
  [setMyInfoPending]: setMyInfoPendingReducer,
  [setMyInfoSuccess]: setMyInfoSuccessReducer,
  [setMyInfoFailure]: setMyInfoFailureReducer,
});

export default memberReducer;

/* Sagas */

function* fetchUpdateMember({ payload }) {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib');
  try {
    const member = yield call(
      fetchWAuth,
      '/.netlify/functions/update-member-info',
      {},
      payload,
    ) || {};
    if (!member || member.errors) {
      alert('Error updating member information.');
      yield put(setUpdateMemberFailure(member.errors));
    } else {
      yield put(setUpdateMemberSuccess(member));
    }
  } catch (error) {
    alert('Error updating member information.');
    yield put(setUpdateMemberFailure([error]));
  }
}

export function* watchUpdateMemberInfo() {
  yield takeEvery(updateMember().type, fetchUpdateMember);
}

function* fetchSurveyUpdateMember({ payload }) {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib');
  try {
    const { id, data } = payload;
    const { member = {} } = yield call(fetchWAuth, '/.netlify/functions/update-member-info', { body: JSON.stringify({ id, data }) });
    if (!member || member.errors) {
      alert('Error updating member information.');
      yield put(setUpdateMemberFailure(member.errors));
    } else {
      yield put(setUpdateMemberSuccess(get(member, 'member', [])));
    }
  } catch (error) {
    alert('Error updating member information.');
    yield put(setUpdateMemberFailure([error]));
  }
}

export function* watchSurveyUpdateMemberInfo() {
  yield takeEvery(setUpdateMemberSurvey().type, fetchSurveyUpdateMember);
}

function* fetchMyInfoSaga() {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib.js');
  /* TODO: [ACT-227] Strange error where fetchWAuth is not loaded during startup */
  if (fetchWAuth) {
    try {
      yield put(setMyInfoPending());
      const response = yield call(fetchWAuth, '/.netlify/functions/get-my-info');
      if (!response || response.errors) {
        alert('Error fetching your profile');
        yield put(setMyInfoFailure(response.errors));
      } else {
        yield put(setMyInfoSuccess(_.cloneDeep(response)));
      }
    } catch (error) {
      alert('Error fetching your profile');
      yield put(setMyInfoFailure([error]));
    }
  }
}

export function* watchFetchMyInfoSaga() {
  yield takeEvery(fetchMyInfo().type, fetchMyInfoSaga);
}

export const Sagas = [
  watchUpdateMemberInfo, watchFetchMyInfoSaga, watchSurveyUpdateMemberInfo,
];
