/* eslint-disable implicit-arrow-linebreak */
import store from './redux/configureStore';
import { triggerLogin, triggerLogout } from './redux/user';

export const noop = () => null;

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const expandeMemberData = (member = {}) => {
  let expandedMemberData = {};
  try {
    expandedMemberData = { ...member, ...JSON.parse(member.data) };
  } catch (err) {
    expandedMemberData = member;
  }
  return expandedMemberData;
};

export const memberLevelCheckBuilder = (membershipLevel) => {
  const isNew = () => (membershipLevel === 'initial' || membershipLevel === 'new' || !membershipLevel);
  const isInitial = () => membershipLevel === 'initial';
  const isMember = () => membershipLevel === 'member';
  return { isNew, isInitial, isMember };
};

export const roles = {
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  MEMBER: 'member',
  FREE: 'free',
};

export const hasRole = (rolesArray = []) => (role = []) => {
  if (rolesArray.reduce((prev, userRole) => prev || role.includes(userRole), false)) {
    return true;
  }
  return false;
};

/* Identity only works in the browser disable for SSR */
const netlifyIdentity = typeof document === 'undefined' ? { on: noop, init: noop } : require('netlify-identity-widget');

let user = null;
export const getUser = () => user;
export const getNetlifyIdentity = () => netlifyIdentity;

export const handleUserStateChange = (type) => (userData) => {
  user = userData;
  switch (type) {
    case 'open':
      break;
    case 'close':
    case 'init':
      break;
    case 'login':
      store.dispatch(triggerLogin(user));
      netlifyIdentity.close();
      break;
    case 'logout':
      store.dispatch(triggerLogout());
      break;
    default:
      break;
  }
};

netlifyIdentity.on('error', handleUserStateChange('error'));
netlifyIdentity.on('open', handleUserStateChange('open'));
netlifyIdentity.on('close', handleUserStateChange('close'));
netlifyIdentity.on('init', handleUserStateChange('init'));
netlifyIdentity.on('login', handleUserStateChange('login'));
netlifyIdentity.on('logout', handleUserStateChange('logout'));
netlifyIdentity.init();

/**
 *
 * @param {*} url URL to Fetch
 * @param {*} config Fetch Config Object to overide default
  */
export const fetchWAuth = (
  url,
  config = {},
  data,
) => {
  const fetchDataAsync = async () => {
    /* TODO: [ACT-228] Refactor this function */
    let currentUser = await netlifyIdentity.currentUser();
    if (currentUser) {
      let token = await currentUser.jwt(true);

      try {
        if (!token) {
        // Try again but this time refreshing the token
          await netlifyIdentity.refresh();
          currentUser = await netlifyIdentity.currentUser();
          token = await currentUser.jwt(true);
        }
      } catch (error) {
        //console.log(error);
      }
      if (!token) {
        store.dispatch(triggerLogout());
      }
      const baseConfig = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      };

      return fetch(url, { ...baseConfig, ...config })
        .then((res) => res.json())
        .catch((errMessage) => {
        // eslint-disable-next-line no-console
          console.error(errMessage);
        });
    }
    return {};
  };

  return fetchDataAsync();
};
