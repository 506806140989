/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { put, takeEvery, call } from 'redux-saga/effects';
import _, { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { findPurchaseByID, findPurchaseByEventID } from '../lib/purchases';
import fetchPublic from '../lib/fetchPublic';

const updateObjectInsideArray = (identifier, data) => {
  const dataCopy = [...data];
  const index = _.findIndex(dataCopy, { _id: identifier });
  return (newObj) => {
    // eslint-disable-next-line no-param-reassign
    dataCopy.splice(index, 1, { ...data[index], ...newObj });
    return dataCopy;
  };
};

/* https://redux-toolkit.js.org/ */

/* Actions */
export const fetchPurchases = createAction('PURCHASE/FETCH');
export const setPurchasesSuccess = createAction('PURCHASE/SET_PURCHASES_SUCCESS');
export const setPurchasesFailure = createAction('PURCHASE/SET_PURCHASES_FAILURE');

export const createPurchase = createAction('PURCHASE/CREATE_PURCHASE');
export const setCreatePurchasePending = createAction('PURCHASE/CREATE_PURCHASE_PENDING');
export const setCreatePurchaseSuccess = createAction('PURCHASE/CREATE_PURCHASE_SUCCESS');
export const setCreatePurchaseFailure = createAction('PURCHASE/CREATE_PURCHASE_FAILURE');

export const updatePurchase = createAction('PURCHASES/UPDATE_PURCHASE');
export const setUpdatePurchasePending = createAction('PURCHASES/UPDATE_PURCHASE_PENDING');
export const setUpdatePurchaseSuccess = createAction('PURCHASES/UPDATE_PURCHASE_SUCCESS');
export const setUpdatePurchaseFailure = createAction('PURCHASES/UPDATE_PURCHASE_FAILURE');

export const Actions = {
  fetchPurchases,
  setPurchasesSuccess,
  setPurchasesFailure,
  createPurchase,
  setCreatePurchasePending,
  setCreatePurchaseSuccess,
  setCreatePurchaseFailure,
  updatePurchase,
  setUpdatePurchasePending,
  setUpdatePurchaseSuccess,
  setUpdatePurchaseFailure,
};

/* Selectors */
export const getPurchases = (state) => get(state, 'purchase.purchases', []);
export const getPurchaseByID = (id) => (state) => findPurchaseByID(get(state, 'purchase.purchases', []), id);
export const getPurchaseByEventID = (id) => (state) => findPurchaseByEventID(get(state, 'purchase.purchases', []), id);
export const Selectors = { getPurchases };

/* Initial State */
export const initialState = { purchases: [], errors: [] };

/* Reducer */
const setPurchasesSuccessReducer = (state, action) => ({ ...state, purchases: action.payload, errors: [] });
const setPurchasesFailureReducer = (state, action) => ({ ...state, purchases: [], errors: action.payload });

const setCreatePurchasePendingReducer = (state) => ({ ...state, isCreatingPurchase: true });
const setCreatePurchaseSuccessReducer = (state, action) => ({
  ...state, isCreatingPurchase: false, purchases: [...state.purchases, action.payload], errors: [],
});
const setCreatePurchaseFailureReducer = (state, action) => ({ ...state, isCreatingPurchase: false, errors: action.payload });

const setUpdatePurchasePendingReducer = (state) => ({
  ...state,
  isUpdating: true,
});
const setUpdatePurchaseSuccessReducer = (state, action) => ({
  // eslint-disable-next-line no-underscore-dangle
  ...state,
  isUpdating: false,
  purchases: updateObjectInsideArray(
    action.payload._id,
    state.purchases,
  )(action.payload),
});
const setUpdatePurchaseFailureReducer = (state, action) => ({
  ...state,
  isUpdating: false,
  error: action.payload,
});

const eventReducer = createReducer(initialState, {
  [setPurchasesSuccess]: setPurchasesSuccessReducer,
  [setPurchasesFailure]: setPurchasesFailureReducer,
  [setCreatePurchasePending]: setCreatePurchasePendingReducer,
  [setCreatePurchaseSuccess]: setCreatePurchaseSuccessReducer,
  [setCreatePurchaseFailure]: setCreatePurchaseFailureReducer,
  [setUpdatePurchasePending]: setUpdatePurchasePendingReducer,
  [setUpdatePurchaseSuccess]: setUpdatePurchaseSuccessReducer,
  [setUpdatePurchaseFailure]: setUpdatePurchaseFailureReducer,
});

export default eventReducer;

/* Sagas */

function* fetchUpdatePurchase({ payload }) {
  try {
    yield put(setUpdatePurchasePending());
    const purchase = yield call(
      fetchPublic,
      '/.netlify/functions/update-purchase',
      {},
      payload,
    ) || {};
    if (!purchase || purchase.errors) {
      alert('Error updating purchase');
      yield put(setUpdatePurchaseFailure(purchase.errors));
    } else {
      yield put(setUpdatePurchaseSuccess(purchase));
    }
  } catch (error) {
    alert('Error updating purchase');
    yield put(setUpdatePurchaseFailure([error]));
  }
}

export function* watchUpdatePurchase() {
  yield takeEvery(updatePurchase().type, fetchUpdatePurchase);
}

function* fetchRemotePurchases() {
  try {
    const purchases = yield call(fetchPublic, '/.netlify/functions/get-all-purchases');
    if (purchases.errors) {
      alert('Error fetching purchases');
      yield put(setPurchasesSuccess(purchases.errors));
    } else {
      yield put(setPurchasesSuccess(purchases));
    }
  } catch (error) {
    alert('Error fetching purchases');
    yield put(setPurchasesSuccess([error]));
  }
}

export function* watchFetchPurchases() {
  yield takeEvery(fetchPurchases().type, fetchRemotePurchases);
}

function* fetchCreatePurchase({ payload }) {
  const { data } = payload;
  try {
    yield put(setCreatePurchasePending());
    const purchase = yield call(fetchPublic, '/.netlify/functions/create-purchase', {}, data) || {};
    if (!purchase || purchase.errors) {
      alert('Error creating purchases.');
      yield put(setCreatePurchaseFailure(purchase.errors));
    } else {
      yield put(setCreatePurchaseSuccess(purchase));
    }
  } catch (error) {
    alert('Error creating purchases.');
    yield put(setCreatePurchaseFailure([error]));
  }
}

export function* watchCreatePurchase() {
  yield takeEvery(createPurchase().type, fetchCreatePurchase);
}

export const Sagas = [
  watchUpdatePurchase,
  watchFetchPurchases,
  watchCreatePurchase,
];
