import { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';

/* https://redux-toolkit.js.org/ */

/* Actions */
export const addTodo = createAction('ADD_TODO');
export const removeTodo = createAction('REMOTE_TODO');
export const toggleTodo = createAction('TOGGLE_TODO');
export const Actions = { addTodo, removeTodo, toggleTodo };

/* Selectors */
export const getTodos = (state) => get(state, 'todos', []);
export const getTodo = (state, index) => get(state, `todos.${index}`, {});
export const Selectors = { getTodos, getTodo };

/* Initial State */
export const initialState = { todos: [] };

/* Reducer */
const ADD_TODO = (state, action) => state.todo.push(action.payload);
const REMOVE_TODO = (state, action) => state.todos.filter((todo, i) => i !== action.payload.index);
const TOGGLE_TODO = (state, action) => {
  const todo = state.todo[action.payload.index];
  todo.completed = !todo.completed;
};

const exampleReducer = createReducer(initialState, { ADD_TODO, TOGGLE_TODO, REMOVE_TODO });

export default exampleReducer;
