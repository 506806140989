/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

export const findPurchaseByID = (data, id) => _.filter(data, (item) => item._id === id);
export const findPurchaseByEventID = (data, id) => {
  const purchases = _.filter(data, (item) => {
    if (!item.event) return false;
    return item.event._id === id;
  });
  const expandedPurchases = purchases.length >= 1 ? _.map(purchases, (item) => ({
    ...item,
    transaction:
      {
        ...item.transaction,
        transactionData: JSON.parse(item.transaction.transactionData),
      },
  })) : [];
  return expandedPurchases;
};
