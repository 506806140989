/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import _ from 'lodash';

export const transformSpecialitiesStringToID = (specialities = '') => specialities
  .toLocaleLowerCase()
  .trim()
  .replace(/[^\w\s]|_/g, '') // Remove Punctiation
  .replace(/\s+/g, ' ') // Condense Spaces to a single space
  .replace(/\s/g, '_'); // Replaces spaces with an _

export const filterContractorData = (data, specialitiesTypeID) => data.filter((item) => {
  const contractorSpecialities = (item.companySpecialities || '').split(',');
  return contractorSpecialities.some((specialities) => transformSpecialitiesStringToID(specialities) === specialitiesTypeID);
});

export const filterAdminContractors = (data) => data.filter(({ role }) => role && role.isAdmin);

// Note the descripency between id and _id
export const filterContractorDataById = (data, id) => data.filter((item) => id === item.id || id === item._id);

export const filterEmergencyContractorData = (data) => data.filter((item) => item.emergency);
export const filterNewContractorData = (data) => data.filter(({ role }) => role && role.isGuest);

export const filterOnlyMembers = (data) => data.filter(({ role, adminReviewed }) => (role && role.isMember) && adminReviewed);

export const filterPendingContractorData = (data) => data.filter(({ role, adminReviewed }) => (role && role.isPending) || !adminReviewed);

export const transformContractorData = (data) => {
  const specialitiess = data.reduce((prev, curr) => {
    const contractorSpecialities = _.get(curr, 'companySpecialities', '');
    if (contractorSpecialities !== null) {
      return [...prev, ...contractorSpecialities.split(',')];
    }
    return [...prev];
  }, []).sort();

  const deDupedSpecialities = specialitiess
    .map((specialities) => {
      const specialitiesID = transformSpecialitiesStringToID(specialities);
      return { id: specialitiesID, label: specialities };
    })
    .reduce((prev, curr) => {
      if (prev.some((needle) => needle.id === curr.id)) {
        return prev;
      }
      return [...prev, curr];
    }, [])
    .sort((a, b) => (a.label - b.label));

  return deDupedSpecialities;
};

export const filterArchived = (members) => (members.filter((member) => !member.isArchived));
export const filterOnlyArchivedMembers = (members) => (members.filter((member) => member.isArchived));
