/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  put, takeEvery, call,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';

import fetchPublic from '../lib/fetchPublic';

/* Actions */
export const product = createAction('PRODUCT/PRODUCT');
export const productPending = createAction('PRODUCT/PRODUCT_PENDING');
export const productSuccess = createAction('PRODUCT/PRODUCT_SUCCESS');
export const productFailure = createAction('PRODUCT/PRODUCT_FAILURE');

export const Actions = {
  product, productPending, productSuccess, productFailure,
};

/* Selectors */
export const getAllProducts = (state) => get(state, 'products.products', []);

/* Initial State */
export const initialState = {
  isLoading: false,
  products: [],
  errors: [],
};

/* Reducer */
const productPendingReducer = (state) => ({
  ...state,
  isLoading: true,
});

const productSuccessReducer = (state, action) => ({
  ...state,
  isLoading: false,
  products: action.payload,
});

const productFailureReducer = (state, action) => ({
  ...state,
  isLoading: false,
  errors: action.payload,
});

const productsReducer = createReducer(initialState, {
  [productPending]: productPendingReducer,
  [productSuccess]: productSuccessReducer,
  [productFailure]: productFailureReducer,
});

export default productsReducer;

/* Sagas */

function* fetchProducts({ payload }) {
  try {
    yield put(productPending());
    const { products = null } = yield call(fetchPublic, '/.netlify/functions/get-all-stripe-products', { body: JSON.stringify(payload) });
    if (!products || products.errors) {
      alert('Error fetching products');
      yield put(productFailure(products.errors));
    } else {
      yield put(productSuccess(products));
    }
  } catch (error) {
    alert('Error fetching products');
    yield put(productFailure(error));
  }
}

export function* watchProducts() {
  yield takeEvery(product().type, fetchProducts);
}

export const Sagas = [
  watchProducts,
];
