/* eslint-disable no-alert */
/* eslint-disable max-len */
import { put, takeEvery, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';
import fetchPublic from '../lib/fetchPublic';

/* https://redux-toolkit.js.org/ */

/* Actions */
export const fetchEventsItems = createAction('EVENTS_ITEMS/FETCH');
export const setEventsItemsSuccess = createAction('EVENTS_ITEMS/SET_EVENTS_ITEMS_SUCCESS');
export const setEventsItemsFailure = createAction('EVENTS_ITEMS/SET_EVENTS_ITEMS_FAILURE');

export const createEventItem = createAction('EVENTS_ITEMS/CREATE_EVENT_ITEM');
export const setCreateEventItemPending = createAction('EVENTS_ITEMS/CREATE_EVENT_ITEM_PENDING');
export const setCreateEventItemSuccess = createAction('EVENTS_ITEMS/CREATE_EVENT_ITEM_SUCCESS');
export const setCreateEventItemFailure = createAction('EVENTS_ITEMS/CREATE_EVENT_ITEM_FAILURE');

export const archiveEventItem = createAction('EVENTS_ITEMS/ARCHIVE_EVENT_ITEM');
export const setArchiveEventItemPending = createAction(
  'EVENTS_ITEMS/ARCHIVE_EVENT_ITEM_PENDING',
);
export const setArchiveEventItemSuccess = createAction(
  'EVENTS_ITEMS/ARCHIVE_EVENT_ITEM_SUCCESS',
);
export const setArchiveEventItemFailure = createAction(
  'EVENTS_ITEMS/ARCHIVE_EVENT_ITEM_FAILURE',
);

export const Actions = {
  fetchEventsItems,
  setEventsItemsSuccess,
  setEventsItemsFailure,
  createEventItem,
  archiveEventItem,
};

/* Selectors */
export const getEventsItems = (state) => get(state, 'eventsItems.eventsItems', []);
export const getIsDeletingEventItem = (state) => get(state, 'eventsItems.isDeletingEventItem', false);
export const Selectors = { getEventsItems };

/* Initial State */
export const initialState = { eventsItems: [], errors: [] };

/* Reducer */
const setEventsItemsSuccessReducer = (state, action) => ({ ...state, eventsItems: action.payload.filter((item) => !item.isArchived), errors: [] });
const setEventsItemsFailureReducer = (state, action) => ({ ...state, eventsItems: [], errors: action.payload });

const setCreateEventItemPendingReducer = (state) => ({
  ...state,
  isCreatingEventItem: true,
});
const setCreateEventItemSuccessReducer = (state, action) => ({
  ...state,
  isCreatingEventItem: false,
  eventsItems: [...state.eventsItems, action.payload],
  errors: [],
});
const setCreateEventItemFailureReducer = (state, action) => ({
  ...state,
  isCreatingEventItem: false,
  errors: action.payload,
});

const setArchiveEventItemPendingReducer = (state) => ({
  ...state,
  isArchivingEventItem: true,
});
const setArchiveEventItemSuccessReducer = (state, action) => ({
  ...state,
  isArchivingEventItem: false,
  eventsItems: [...state.eventsItems].filter(
    (item) => ((item._id !== action.payload._id) && !item.isArchived),
  ),
  errors: [],
});
const setArchiveEventItemFailureReducer = (state, action) => ({
  ...state,
  isArchivingEventItem: false,
  errors: action.payload,
});

const eventItemReducer = createReducer(initialState, {
  [setEventsItemsSuccess]: setEventsItemsSuccessReducer,
  [setEventsItemsFailure]: setEventsItemsFailureReducer,
  [setCreateEventItemPending]: setCreateEventItemPendingReducer,
  [setCreateEventItemSuccess]: setCreateEventItemSuccessReducer,
  [setCreateEventItemFailure]: setCreateEventItemFailureReducer,
  [setArchiveEventItemPending]: setArchiveEventItemPendingReducer,
  [setArchiveEventItemSuccess]: setArchiveEventItemSuccessReducer,
  [setArchiveEventItemFailure]: setArchiveEventItemFailureReducer,
});

export default eventItemReducer;

/* Sagas */

function* fetchRemoteEventsItems() {
  if (fetchPublic) {
    try {
      const { eventsItems = {} } = yield call(fetchPublic, '/.netlify/functions/get-all-event-items');
      if (eventsItems.errors) {
        alert('Error fetching event items.');
        yield put(setEventsItemsFailure(eventsItems.errors));
      } else {
        yield put(setEventsItemsSuccess(eventsItems));
      }
    } catch (error) {
      alert('Error fetching event items.');
      yield put(setEventsItemsFailure([error]));
    }
  }
}

export function* watchFetchEventsItems() {
  yield takeEvery(fetchEventsItems().type, fetchRemoteEventsItems);
}

function* fetchCreateEventItem({ payload }) {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib');
  try {
    yield put(setCreateEventItemPending());
    const event = yield call(fetchWAuth, '/.netlify/functions/create-event-item', {}, payload) || {};
    if (!event || event.errors) {
      alert('Error creating event item.');
      yield put(setCreateEventItemFailure(event.errors));
    } else {
      yield put(setCreateEventItemSuccess(event));
    }
  } catch (error) {
    alert('Error creating event item.');
    yield put(setCreateEventItemFailure([error]));
  }
}

export function* watchCreateEventItem() {
  yield takeEvery(createEventItem().type, fetchCreateEventItem);
}

function* fetchDeleteEventItem({ payload }) {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib');
  try {
    yield put(setArchiveEventItemPending());
    const event = yield call(
      fetchWAuth,
      '/.netlify/functions/archive-event-item',
      {},
      payload,
    ) || {};
    console.log(event);
    if (!event || event.errors) {
      yield put(setArchiveEventItemFailure(event.errors));
    } else {
      yield put(setArchiveEventItemSuccess(event));
      alert('Event item has been archived successfully.');
    }
  } catch (error) {
    alert('Error archiving event item.');
    yield put(setArchiveEventItemFailure([error]));
  }
}

export function* watchDeleteEventItem() {
  yield takeEvery(archiveEventItem().type, fetchDeleteEventItem);
}

export const Sagas = [
  watchFetchEventsItems,
  watchCreateEventItem,
  watchDeleteEventItem,
];
