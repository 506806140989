import { get } from 'lodash';
import { put, takeEvery, call } from 'redux-saga/effects';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { fetchMyInfo, setMyInfoFailure } from './member';
import { fetchEvents } from './event';
import { fetchMembers } from './members';
import { fetchPurchases } from './purchase';
import { fetchEventsItems } from './eventsItems';

/* Actions */
export const triggerLogin = createAction('USER/TRIGGER_LOGIN');
export const triggerLogout = createAction('USER/TRIGGER_LOGOUT');
export const saveMemberId = createAction('USER/SAVE_MEMBER_ID');
export const addUserSessionInformation = createAction('USER/ADD_USER_SESSION_INFORMATION');
export const setAuthenticated = createAction('USER/SET_AUTHENTICATED');

export const Actions = {
  saveMemberId, addUserSessionInformation, setAuthenticated,
};

/* Selectors */
export const getSearchResults = (state) => get(state, 'retrievedContractors', []);
export const getSessionUser = (state) => get(state, 'user.session', {});

/*
 The Below line won't work consistently because tokens expire after 5 minutes see lib.js for
 the correct way to do this
 */
// export const getSessionToken = (state) => get(state, 'session', '');

export const getAuthenticated = (state) => get(state, 'user.authenticated', false);

export const Selectors = { getSearchResults, getAuthenticated };
/* Initial State */
export const initialState = {
  memberDocumentId: null,
  session: '',
};

/* Reducers */
const setAuthenticatedReducer = (state, { payload }) => ({
  ...state,
  authenticated: payload,
});
const addUserSessionInformationReducer = (state, { payload }) => (
  {
    ...state,
    session: payload,
  });

const saveMemberIdReducer = (state, action) => ({
  ...state,
  memberDocumentId: action.payload,
});

const userReducer = createReducer(initialState,
  {
    [setAuthenticated]: setAuthenticatedReducer,
    [saveMemberId]: saveMemberIdReducer,
    [addUserSessionInformation]: addUserSessionInformationReducer,
  });

export default userReducer;

/* Sagas */

function* loginSaga({ payload }) {
  const user = payload;
  // eslint-disable-next-line global-require
  try {
    yield put(addUserSessionInformation(user));
    yield put(fetchMyInfo());
    yield put(fetchEventsItems());
    yield put(fetchPurchases());
    yield put(fetchMembers());
    yield put(fetchEvents());
    yield put(setAuthenticated(true));
  } catch (error) {
    //console.log(error);
    yield put(addUserSessionInformation({}));
    yield put(setAuthenticated(false));
  }
}

function* logoutSaga() {
  // eslint-disable-next-line global-require
  yield put(setAuthenticated(false));
  yield put(addUserSessionInformation({}));
  yield put(setMyInfoFailure({}));
}

export function* watchLogins() {
  yield takeEvery(triggerLogin().type, loginSaga);
  yield takeEvery(triggerLogout().type, logoutSaga);
}

export const Sagas = [
  watchLogins,
];
