import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
/*
  NOTE while currently reducers are in MTC branch, ideally these will be shared between act and MTC,
  however for dev simplicity and I keeping them here i.e.

  import example from '@allthingswww/client-act-shared'
*/

import example from './example';
import signup from './signup';
import rootSaga from './sagas';
import user, { Sagas as userSagas } from './user';
import members, { Sagas as membersSagas } from './members';
import eventsItems, { Sagas as eventsItemsSagas } from './eventsItems';
import member, { Sagas as memberSagas } from './member';
import app, { Sagas as appSagas } from './app';
import payments, { Sagas as paymentsSaga } from './payments';
import event, { Sagas as eventSagas } from './event';
import purchase, { Sagas as purchaseSagas } from './purchase';
import products, { Sagas as productSagas } from './stripeProducts';

import eventCheckout from './eventCheckout';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    app,
    example,
    signup,
    members,
    user,
    eventsItems,
    member,
    event,
    payments,
    eventCheckout,
    purchase,
    products,
  },
  middleware: [sagaMiddleware],
});

const sagas = [
  ...memberSagas,
  ...membersSagas,
  ...eventsItemsSagas,
  ...userSagas,
  ...appSagas,
  ...eventSagas,
  ...paymentsSaga,
  ...purchaseSagas,
  ...productSagas,
];

sagaMiddleware.run(rootSaga(sagas));

export default store;
