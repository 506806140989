import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  put, takeEvery, call,
} from 'redux-saga/effects';

/* Actions */
export const addUserSessionInformation = createAction('ADD_USER_SESSION_INFORMATION');
export const addOrganizationType = createAction('ADD_ORGANIZATION_TYPE');
export const addPersonalInfo = createAction('ADD_PERSONAL_INFO');
export const addBusinessInfo = createAction('ADD_BUSINESS_INFO');

export const Actions = {
  addOrganizationType, addPersonalInfo, addBusinessInfo, addUserSessionInformation,
};

/* Selectors */

/* Initial State */
export const initialState = {
  pending: false,
  data: {
    selectedPlan: {
      id: '',
      name: '',
      shortDescription: '',
    },
    stepOne: {
      fullName: '',
      email: '',
      phone: '',
      fax: '',
      companyName: '',
    },
    stepTwo: {
      companyPoBox: '',
      companyCity: '',
      companyState: '',
      companyZip: '',
      companyWebsite: '',
      principalTypeOfBusiness: '',
      yearsInBusiness: '',
      nameOfSeniorOfficerOrLocalManager: '',
      nameOfRepresentative: '',
      alternateRepresentative: '',
      nameOfSponsor: '',
    },
    selectedNatureOfBusiness: {
      id: 'option-1',
      name: 'Option 1',
      shortDescription: 'My business is actively engaged in subcontracting or supplying materials to the construction industry',
    },
  },
  session: {},
  error: null,
};

const addSignUpInfoReducer = createReducer(initialState,
  {
    [addUserSessionInformation]: (state, { payload }) => {
      const { data } = state;
      return (
        {
          ...state,
          data: { ...data },
          session: payload,
        });
    },
    [addOrganizationType]: (state, { payload }) => {
      const { data } = state;
      return (
        {
          ...state,
          data: { ...data, [Object.keys(payload)[0]]: payload[Object.keys(payload)[0]] },
        });
    },

  });

/* Sagas */
async function updateMemberInfo(token) {
  fetch('/.netlify/functions/update-member-info', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((res) => res.json())
    .then((data) => (data))
    // eslint-disable-next-line no-console
    .catch((errMessage) => console.error(errMessage));
}

function* changeMemberInfo() {
  try {
    const response = yield call(updateMemberInfo);
    // eslint-disable-next-line no-unused-vars
    const contractors = response.data;
    // yield put(searchContractorsSuccess(contractors));
  } catch (error) {
    // yield put(searchContractorsSuccess(error));
  }
}

export function* watchGetContractors() {
  yield takeEvery('SEARCH_CONTRACTORS_PENDING', changeMemberInfo);
}

export default addSignUpInfoReducer;
