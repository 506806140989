/* eslint-disable import/no-cycle */
/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';
import _, { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { findEventByID, findPublishedEvent } from '../lib/events';
import fetchPublic from '../lib/fetchPublic';
import { getAuthenticated } from './user';

const updateObjectInsideArray = (identifier, data) => {
  const dataCopy = [...data];
  const index = _.findIndex(dataCopy, { _id: identifier });
  return (newObj) => {
    // eslint-disable-next-line no-param-reassign
    dataCopy.splice(index, 1, { ...data[index], ...newObj });
    return dataCopy;
  };
};

/* https://redux-toolkit.js.org/ */

/* Actions */
export const fetchEvents = createAction('EVENTS/FETCH');
export const setEventsSuccess = createAction('EVENTS/SET_EVENTS_SUCCESS');
export const setEventsFailure = createAction('EVENTS/SET_EVENTS_FAILURE');

export const createEvent = createAction('EVENTS/CREATE_EVENT');
export const setCreateEventPending = createAction('EVENTS/CREATE_EVENT_PENDING');
export const setCreateEventSuccess = createAction('EVENTS/CREATE_EVENT_SUCCESS');
export const setCreateEventFailure = createAction('EVENTS/CREATE_EVENT_FAILURE');

export const updateEvent = createAction('EVENTS/UPDATE_EVENT');
export const setUpdateEventPending = createAction('EVENTS/UPDATE_EVENT_PENDING');
export const setUpdateEventSuccess = createAction('EVENTS/UPDATE_EVENT_SUCCESS');
export const setUpdateEventFailure = createAction('EVENTS/UPDATE_EVENT_FAILURE');

export const Actions = {
  fetchEvents,
  setEventsSuccess,
  setEventsFailure,
  createEvent,
  setCreateEventPending,
  setCreateEventSuccess,
  setCreateEventFailure,
  updateEvent,
  setUpdateEventPending,
  setUpdateEventSuccess,
  setUpdateEventFailure,
};

/* Selectors */
export const getPublishedEvents = (state) => findPublishedEvent(get(state, 'event.events', []));
export const getEvents = (state) => get(state, 'event.events', []);
export const getEventByID = (id) => (state) => findEventByID(get(state, 'event.events', []), id);
export const Selectors = { getEvents };

/* Initial State */
export const initialState = { events: [], errors: [] };

/* Reducer */
const setEventsSuccessReducer = (state, action) => ({ ...state, events: action.payload, errors: [] });
const setEventsFailureReducer = (state, action) => ({ ...state, events: [], errors: action.payload });

const setCreateEventPendingReducer = (state) => ({ ...state, isCreatingEvent: true });
const setCreateEventSuccessReducer = (state, action) => ({
  ...state, isCreatingEvent: false, events: [...state.events, action.payload], errors: [],
});
const setCreateEventFailureReducer = (state, action) => ({ ...state, isCreatingEvent: false, errors: action.payload });

const setUpdateEventPendingReducer = (state) => ({
  ...state,
  isUpdating: true,
});
const setUpdateEventSuccessReducer = (state, action) => ({
  // eslint-disable-next-line no-underscore-dangle
  ...state,
  isUpdating: false,
  events: updateObjectInsideArray(
    action.payload._id,
    state.events,
  )(action.payload),
});
const setUpdateEventFailureReducer = (state, action) => ({
  ...state,
  isUpdating: false,
  error: action.payload,
});

const eventReducer = createReducer(initialState, {
  [setEventsSuccess]: setEventsSuccessReducer,
  [setEventsFailure]: setEventsFailureReducer,
  [setCreateEventPending]: setCreateEventPendingReducer,
  [setCreateEventSuccess]: setCreateEventSuccessReducer,
  [setCreateEventFailure]: setCreateEventFailureReducer,
  [setUpdateEventPending]: setUpdateEventPendingReducer,
  [setUpdateEventSuccess]: setUpdateEventSuccessReducer,
  [setUpdateEventFailure]: setUpdateEventFailureReducer,
});

export default eventReducer;

/* Sagas */

function* fetchUpdateEvent({ payload }) {
  const isAuthenticated = yield select(getAuthenticated);
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib');
  try {
    yield put(setUpdateEventPending());
    let event;
    if (!isAuthenticated && (payload.action === 'updateRemainingSeats')) {
      event = yield call(fetchPublic, '/.netlify/functions/update-event-public', {}, payload) || {};
    }
    event = yield call(fetchWAuth, '/.netlify/functions/update-event', {}, payload) || {};
    if (!event || event.errors) {
      alert('Error updating event.');
      yield put(setUpdateEventFailure(event.errors));
    } else {
      alert('Event updated successfully');
      yield put(setUpdateEventSuccess(event));
    }
  } catch (error) {
    alert('Error updating event.');
    yield put(setUpdateEventFailure([error]));
  }
}

export function* watchUpdateEvent() {
  yield takeEvery(updateEvent().type, fetchUpdateEvent);
}

function* fetchRemoteEvents() {
  try {
    const events = yield call(fetchPublic, '/.netlify/functions/get-all-events');
    if (!events || events.errors) {
      alert('Error fetching remote events.');
      yield put(setEventsSuccess(events.errors));
    } else {
      yield put(setEventsSuccess(events));
    }
  } catch (error) {
    alert('Error fetching remote events.');
    yield put(setEventsSuccess([error]));
  }
}

export function* watchFetchEvents() {
  yield takeEvery(fetchEvents().type, fetchRemoteEvents);
}

function* fetchCreateEvent({ payload }) {
  // eslint-disable-next-line global-require
  const { fetchWAuth } = require('../lib');
  try {
    yield put(setCreateEventPending());
    const event = yield call(fetchWAuth, '/.netlify/functions/create-event', {}, payload) || {};
    if (!event || event.errors) {
      alert('Error creating event.');
      yield put(setCreateEventFailure(event.errors));
    } else {
      yield put(setCreateEventSuccess(event));
    }
  } catch (error) {
    alert('Error creating event.');
    yield put(setCreateEventFailure([error]));
  }
}

export function* watchCreateEvent() {
  yield takeEvery(createEvent().type, fetchCreateEvent);
}

export const Sagas = [
  watchUpdateEvent, watchFetchEvents, watchCreateEvent,
];
