/* eslint-disable max-len */
import { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';

/* Actions */
export const updatedCheckoutData = createAction('EVENT_CHECKOUT/UPDATE_CHECKOUT_DATA');
export const updatedCheckoutDataPending = createAction('EVENT_CHECKOUT/UPDATE_CHECKOUT_DATA_PENDING');
export const updatedCheckoutDataSuccess = createAction('EVENT_CHECKOUT/UPDATE_CHECKOUT_DATA_SUCCESS');
export const updatedCheckoutDataFailure = createAction('EVENT_CHECKOUT/UPDATE_CHECKOUT_DATA_FAILURE');

export const Actions = {
  updatedCheckoutData, updatedCheckoutDataPending, updatedCheckoutDataSuccess, updatedCheckoutDataFailure,
};

/* Selectors */

export const getEventCheckoutData = (state) => get(state, 'eventCheckout.data', {});

/* Initial State */
export const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

/* Reducer */
const updatedCheckoutDataReducer = (state) => ({
  ...state,
  isLoading: true,
});

const updatedCheckoutDataSuccessReducer = (state, action) => ({
  ...state,
  isLoading: false,
  data: { ...state.data, ...action.payload },
});
const updatedCheckoutDataFailureReducer = (state, action) => ({
  ...state,
  isLoading: false,
  errors: [...action.payload],
});

const eventCheckoutDataReducer = createReducer(initialState, {
  [updatedCheckoutData]: updatedCheckoutDataReducer,
  [updatedCheckoutDataSuccess]: updatedCheckoutDataSuccessReducer,
  [updatedCheckoutDataFailure]: updatedCheckoutDataFailureReducer,
});

export default eventCheckoutDataReducer;
