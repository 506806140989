import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import store from '../redux/configureStore';
import { appInit } from '../redux/app';

const AppInit = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInit());
  }, []);

  return children;
};

export default ({ element }) => (
  <Provider store={store}>
    <AppInit>{element}</AppInit>
  </Provider>
);
