// eslint-disable-next-line import/prefer-default-export
export { default as wrapRootElement } from './src/components/ReduxWrapper';
require('./src/index.css');

const $ = require('jquery');

export const onInitialClientRender = () => {
  // eslint-disable-next-line no-multi-assign
  window.$ = window.jQuery = $;
};

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://cdn.ckeditor.com/4.14.1/standard/ckeditor.js');
  };
};
