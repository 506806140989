/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  put, takeEvery, call,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { createReducer, createAction } from '@reduxjs/toolkit';

import fetchPublic from '../lib/fetchPublic';

/* Actions */
export const paymentIntent = createAction('PAYMENT/PAYMENTS_INTENT');
export const paymentIntentPending = createAction('PAYMENT/PAYMENTS_INTENT_PENDING');
export const paymentIntentSuccess = createAction('PAYMENT/PAYMENTS_INTENT_SUCCESS');
export const paymentIntentFailure = createAction('PAYMENT/PAYMENTS_INTENT_FAILURE');

export const Actions = {
  paymentIntent, paymentIntentPending, paymentIntentSuccess, paymentIntentFailure,
};

/* Selectors */

export const getClientSecret = (state) => get(state, 'payments.payments.intent.clientSecret', '');

/* Initial State */
export const initialState = {
  payments: {
    intent: {
      isLoading: false,
      clientSecret: '',
      errors: [],
    },
  },
};

/* Reducer */
const paymentIntentPendingReducer = (state) => ({
  ...state,
  payments: {
    intent: {
      isLoading: true,
    },
  },
});
const paymentIntentSuccessReducer = (state, action) => ({
  ...state,
  payments: {
    intent: {
      isLoading: false,
      clientSecret: action.payload,
    },
  },
});
const paymentIntentFailureReducer = (state, action) => ({
  ...state,
  payments: {
    intent: {
      isLoading: false,
      errors: action.payload,
    },
  },
});

const paymentsReducer = createReducer(initialState, {
  [paymentIntentPending]: paymentIntentPendingReducer,
  [paymentIntentSuccess]: paymentIntentSuccessReducer,
  [paymentIntentFailure]: paymentIntentFailureReducer,
});

export default paymentsReducer;

/* Sagas */

function* fetchPaymentIntent({ payload }) {
  try {
    yield put(paymentIntentPending());
    const { clientSecret = {} } = yield call(fetchPublic, '/.netlify/functions/payment-intent', { body: JSON.stringify(payload) });
    if (!clientSecret || clientSecret.errors) {
      alert('Error creating client secret');
      yield put(paymentIntentFailure(clientSecret.errors));
    } else {
      yield put(paymentIntentSuccess(clientSecret));
    }
  } catch (error) {
    alert('Error creating client secret');
    yield put(paymentIntentFailure(error));
  }
}

export function* watchPaymentIntent() {
  yield takeEvery(paymentIntent().type, fetchPaymentIntent);
}

export const Sagas = [
  watchPaymentIntent,
];
